<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">

            <v-dialog v-model="snackbar" persistent max-width="500" >
                <v-card>
                    <v-card-text style="background-color: red" class="scrollable-content error">
                        <v-scroll-y style="max-height: 200px; max-width: 200px; overflow: hidden">
                            <div class="pt-5">
                            <p v-for="(item, index) in errorMessage" :key="index" style="color: white"> {{item}}</p>
                            </div>
                        </v-scroll-y>
                    </v-card-text>
                    <v-card-actions style="background-color: red" class="error">
                        <v-spacer></v-spacer>
                        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <v-row class="mx-5">
                <v-col :cols="5">
                    <v-file-input
                        v-model="is_location.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="IS Location"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="is_location.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="uploadLocation">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="mx-5">
                <v-col :cols="12">
                    <v-btn style="float:right" color="success" @click="mergeAll">Merge All</v-btn>
                </v-col>
            </v-row>

            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="isDetails"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.ancient_data.is_merged`]="{item}">
                                {{item.ancient_data.is_merged ? 'Merged' : 'Not Merged'}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>
                                    <v-btn
                                        v-if="!item.ancient_data.is_merged"
                                        text
                                        color="red"
                                        small
                                        @click="openDialog(item)"
                                        >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        text
                                        color="primary"
                                        @click="showDetails(item), detailsDialog = true"
                                        >
                                        <v-icon>mdi-eye</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-dialog
                v-model="dialog"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;">Are you sure you want to delete? You can't undo this.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelDialog"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="callDelete"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="detailsDialog"
                persistent
                max-width="1200"
                >
                <v-card>
                    <v-card-title>
                        <h5 class="mt-5">{{locationDetails.branch_name}}</h5>
                        
                    </v-card-title>
                    <v-card-text>

                        <v-row class="mt-3">
                            <v-col :cols="4">
                                <v-text-field
                                    label="BP ID Number"
                                    readonly
                                    v-model="locationDetails.bp_idno"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col> 
                            <v-col :cols="4">
                                <v-text-field
                                    label="Sales Prospect Number"
                                    readonly
                                    v-model="locationDetails.sales_prospects_id"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col> 
                            <v-col :cols="4">
                                <v-text-field
                                    label="Location Code"
                                    readonly
                                    v-model="locationDetails.location_code"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col> 
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    label="Address Line 1"
                                    readonly
                                    v-model="locationDetails.address_line_1"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col> 
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    label="Address Line 2"
                                    readonly
                                    v-model="locationDetails.address_line_2"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col> 
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    label="Landline"
                                    readonly
                                    v-model="locationDetails.landline"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col> 
                        </v-row>
                        <v-row>
                            <v-col :cols="4">
								<v-text-field
									label="Country"
                                    value="Philippines"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                            <v-col :cols="4">
								<v-text-field
									label="Province"
                                    v-model="locationDetails.province"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                            <v-col :cols="4">
								<v-text-field
									label="City"
                                    v-model="locationDetails.city"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="6">
								<v-text-field
									label="City"
                                    v-model="locationDetails.city"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                            <v-col :cols="6">
								<v-text-field
									label="City"
                                    v-model="locationDetails.city"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <h4>Coordinates</h4>
                            </v-col>
                            <v-col :cols="6">
								<v-text-field
									label="Lattitude"
                                    v-model="locationDetails.coordinates.lat"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                            <v-col :cols="6">
								<v-text-field
									label="Longtitude"
                                    v-model="locationDetails.coordinates.lng"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
								></v-text-field>
							</v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <h4>Operation Hours</h4>
                            </v-col>
                            <v-col cols="12">
                                <v-card elevation="0">
                                    <v-tabs
                                        v-model="tab"
                                        >
                                        <v-tab
                                            v-for="day in locationDetails.operating_hours"
                                            :key="day.day"
                                            >
                                            <b>{{ day.day }}</b>
                                        </v-tab>
                                    </v-tabs>

                                    <v-tabs-items v-model="tab">
                                        <v-tab-item
                                            v-for="(day) in locationDetails.operating_hours"
                                            :key="day.day"
                                            >
                                            <v-card flat>
                                                <v-row class="my-3">
                                                    <v-col cols="4">
                                                        <v-text-field
                                                            label="Operating hours from"
                                                            hide-details="auto"
                                                            v-model="day.timeStart"
                                                            outlined
                                                            dense
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-text-field
                                                            label="Operating hours to"
                                                            hide-details="auto"
                                                            v-model="day.timeEnd"
                                                            outlined
                                                            dense
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <h4>Archive Data</h4>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                            <v-simple-table
                                fixed-header
                                height="400px"
                            >
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Item
                                    </th>
                                    <th class="text-left">
                                        Value
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(value, variable) in locationDetails.ancient_data" :key="variable"
                                    >
                                    <td>{{ variable }}</td>
                                    <td>{{ value }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mb-5"
                            color="primary"
                            @click="detailsDialog = false, setDefault()"
                            >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-container>
    </div>
</template>
<script>

import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        fab: false,
        isDetails: [],
        headers: [
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'Sales Prospect ID',
                sortable: true,
                value: 'sales_prospects_id'
            },
            {
                text: 'Location ID',
                sortable: true,
                value: 'loc_idno'
            },
            {
                text: 'Branch Name',
                sortable: true,
                value: 'branch_name'
            },
            {
                text: 'Merge Status',
                sortable: true,
                value: 'ancient_data.is_merged'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        acceptedFormats: '.csv',
        errorMessage: [],
        snackbar: false,
        dialog: false,
        bp_idno: '',
        loc_idno: '',
        is_location: {
            file: null
        },
        detailsDialog: false,
        locationDetails: {
            coordinates :{
                lat: '',
                lng: ''
            }
        },
        tab: null
    }
  },
  computed: {
    ...mapGetters({
        })
    },
    async mounted () {
        this.reloadDetails()
        this.setDefault()
    },
    methods: {
        showDetails(e){
            console.log(e)
            this.locationDetails = e
        },
        setDefault(){
            this.is_location = {
                file: null
            },
            this.locationDetails = {
                coordinates :{
                    lat: '',
                    lng: ''
                }
            }
        },
        async reloadDetails(){
            const res = await this.$store.dispatch('informationSystem/doGetIsStandalone')
            console.log(res)
            this.isDetails = res.data.result
        },
        async uploadLocation(){
            const initialPayload = this.is_location
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
            console.log(convertedFormData)
			const payload = {payload: convertedFormData}
            const res = await this.$store.dispatch('informationSystem/doUploadStandaloneLocation', {payload})
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Merged Successfully','messagetype':'success'});
                this.setDefault()
                this.reloadDetails()
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Import Validation Failures'){
                this.errorMessage = []
                this.errorMessage = res.error
                this.snackbar = true
                this.setDefault()
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Bad Request'){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.setDefault()
            } else if (res.code == 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.setDefault()
            }  else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
                this.setDefault()
            }
        },
        cancelDialog(){
            this.dialog = false
            this.bp_idno = ''
        },
        openDialog(e){
            this.bp_idno = e.bp_idno
            this.loc_idno = e.loc_idno
            this.dialog = true
        },
        async callDelete(){
            const res = await this.$store.dispatch('informationSystem/doDeleteStandaloneLocation', this.loc_idno)
            if(res.status == 200){
                this.dialog = false
                this.bp_idno = ''
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Deletion Successful!','messagetype':'success'});
                this.reloadDetails()
            }
        },
        async mergeAll(){
            const res = await this.$store.dispatch('informationSystem/doMergeAllStandalone')
                if (res.status == 200){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Uploaded Successfully','messagetype':'success'});
                    this.$store.commit('informationSystem/SET_DEFAULT')
                    this.reloadDetails()
                } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Merge Validation Failures'){
                    this.errorMessage = []
                    this.errorMessage = res.error
                    this.snackbar = true
                    this.$store.commit('informationSystem/SET_DEFAULT')
                } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Bad Request'){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                    this.$store.commit('informationSystem/SET_DEFAULT')
                } else if (res.status == 422){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                    this.$store.commit('informationSystem/SET_DEFAULT')
                }  else {
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
                    this.$store.commit('informationSystem/SET_DEFAULT')
                }
            }
        },
    }

</script>

<style scoped>
.scrollable-content {
  max-height: 500px;
  overflow-y: auto;
}

/* Hide the scrollbar */
.scrollable-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>